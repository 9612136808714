@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;700&family=Poppins:ital,wght@0,100;0,200;0,400;0,700;0,800;0,900;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

a{
  color: inherit;
  text-decoration: none;
}
.gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 90px;
}
.gallery img {
  width:15%
}
body{
  margin:0;
   padding:0;
   font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif;
background-color: #242323;
}

#title{
  font-family: 'Poppins', sans-serif;
  text-align: center;
  width:50%; color: white;
  font-size: 32px;
  font-weight: 500;
 
}
#title span{
  background-image: linear-gradient(45deg, #FF3086, #FF632A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;  
  font-family: 'Poppins', sans-serif;
  animation: gradient 1s ease-in-out infinite;
}

 
@keyframes gradient{
  0% {
    background-image:linear-gradient(0deg, #FF3086, #FF632A) ;
  }
  20% {
    background-image:linear-gradient(45deg, #FF3086, #FF632A) ;
  }
  40% {
    background-image:linear-gradient(90deg, #FF3086, #FF632A) ;
  }
  60% {
    background-image:linear-gradient(180deg, #FF3086, #FF632A) ;
  }
  80% {
    background-image:linear-gradient(270deg, #FF3086, #FF632A) ;
  }
  100% {
    background-image:linear-gradient(360deg, #FF3086, #FF632A) ;
  }
}

#logo{
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 0px;
  margin-right: 30px;
}

#bottomContent{
  padding-left: 30px;  
  padding-right: 30px;  
  display: none;
  flex-direction: row;
  justify-content: space-around;
  position: fixed;
  bottom: 68px;
   width:100%; 
  background-color: black;
}

#divider {
  border-bottom: #FF3086 solid thin;
  margin: top 10px;
  margin-bottom: 10px;
}

#bottomContent h1 {
  color:white;
  font-weight: 500;
  font-size: 24px; 
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}
 

#bottomContent h1:hover {
  background-image: linear-gradient(45deg, #FF3086, #FF632A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 24px; 
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase; 
}

#active {
  background-image: linear-gradient(45deg, #FF3086, #FF632A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 24px; 
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase; 
}



#hero { 
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 40px;
}

#other{
  padding-left: 30px;  
  padding-right: 30px;  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0px; right:0; left:0;
  
}

#other h4 { 
  /* background-image: linear-gradient(45deg, #FF3086, #FF632A); */
  color: #6f6f6f;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 400;
  font-size: 16px; 
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}
#other h4:hover {  
  color: #ffffff; 
  font-weight: 700;
  font-size: 16px; 
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

#right h4 { 
  /* background-image: linear-gradient(45deg, #FF3086, #FF632A); */
  color: #6f6f6f;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 400;
  font-size: 16px; 
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  text-align: right;
}
#right h4:hover {  
  color: #ffffff; 
  font-weight: 700;
  font-size: 16px; 
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.works{
   margin: 0; padding-left: 120px; padding-right: 120px;
   
   border-bottom: thin solid rgba(255, 48, 134, 0.50);
 
}
.works:hover {
  border-top: 1px solid #FF632A;
  border-bottom: 1px solid #FF632A;
  background: linear-gradient(45deg, rgb(255, 99, 42) 1.37%, rgb(255, 48, 134) 99.51%);
  animation: gradient 1s ease-in-out infinite;
}
#sp{
  margin: 0; padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#sp h1{
  margin: 0; padding: 0;
  color: white;
  font-size: 130px;
}
#sp p{
  margin: 0; padding: 0;
  color: white; text-align: right;
}